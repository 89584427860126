import React from 'react'
import "./Banner.css";
import parcelImg from "../../assets/images/pp.png";
import bannerImg1 from "../../assets/images/banner-img1.jpg";
import bannerImg2 from "../../assets/images/banner-img2.jpg";
import OrderInfo from "../OrderInfo/OrderInfo";
const Banner = () => {
    const showForm = () => {
        document.getElementById("hide").style.display = "none";
        document.getElementById("form-container").style.display = "block";
      };
    return (
        <>
            <section className="banner" >
                {/* Main Banner Section */}
                <div className="banner-info" id="hide">
                    <div className="content">
                        <h1>Nalan Batters</h1>
                        <h3>Freshly Made Everyday</h3> <br />
                        <p><i class="ri-map-pin-2-fill"></i>2623 eglinton ave east, Scarborough, M1k4g5</p>
                        <p><i class="ri-whatsapp-fill"></i> 437 215 4049</p>
                        <p><i class="ri-mail-fill"></i> nalanbatters@gmail.com</p>
                    </div>

                    <img className="img" src={parcelImg} alt="Product" />
                    <div className="banner-buttons">
                        <button className='con-btn'>Contact</button>
                        <button className="buy-btn" onClick={showForm}><span><i class="ri-shopping-cart-2-fill"></i> Place Your Order</span></button>
                    </div>


                </div>
                {/* Form Section */}
                <div id="form-container" className="formpage" style={{ display: "none" }}>
                    <OrderInfo />
                </div>
                {/* Additional Information Section */}
                <div className="banner-images">
                    <img src={bannerImg1} alt="Product 1" />
                    <img src={bannerImg2} alt="Product 2" />
                </div>
            </section>
        </>
    )
}

export default Banner
